<template>
  <section id="iq-upcoming-movie">
    <br />
    <br />
    <br />

    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div
          dir="rtl"
            class="iq-main-header d-flex align-items-center justify-content-between"
          >
            <h4 class="main-title" v-if="imageContents.length > 0">
              {{ imageContents[0]["content_category"]?.[$i18n.locale] }}
            </h4>
            <h4
              class="main-title"
              v-if="imageContents.length == 0 && videoContents.length > 0"
            >
              {{ videoContents[0]["content_category"]?.[$i18n.locale] }}
            </h4>
            <h4
              class="main-title"
              v-if="imageContents.length == 0 && videoContents.length == 0 && audioContents.length > 0"
            >
              {{ audioContents[0]["content_category"]?.[$i18n.locale] }}
            </h4>
          </div>

          <div class="tabs" dir="rtl">
            <button
              :class="{ active: activeTab === 'images' }"
              @click="activeTab = 'images'"
              v-if="hasItems('imageContents')"
            >
              {{ $i18n.locale == 'en' ? 'Images' : $i18n.locale == 'ku' ? 'وێنە' : 'صورة'  }}
            </button>

            <button
              :class="{ active: activeTab === 'videos' }"
              @click="activeTab = 'videos'"
              v-if="hasItems('videoContents')"
            >
            {{ $i18n.locale == 'en' ? 'Videos' : $i18n.locale == 'ku' ? 'ڤیدیۆ' : 'فيديو'  }}

            </button>

            <button
              :class="{ active: activeTab === 'audios' }"
              @click="activeTab = 'audios'"
              v-if="hasItems('audioContents')"
            >
            {{ $i18n.locale == 'en' ? 'Audio' : $i18n.locale == 'ku' ? 'سروود' : 'أناشيد' }}

            </button>
          </div>

          <br />

          <div class="favorites-contens">
            <!-- video contents -->
            <div
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
              v-if="activeTab === 'videos'"
              v-show="activeTab === 'videos'"
            >
              <li
                class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"
                v-for="(item, index) in videoContents"
                :key="index"
              >
                <div class="block-images position-relative">
                  <div class="img-box">
                    <div>



                      <img
                        v-if="item.content_category.en=='Hidden Camera'"
                        src="../../../assets/images/hidden.jpg"
                        class="img-fluid"
                        alt=""
                      />

                      <img
                        v-else-if="item.content_category.en=='Standup Comedy'"
                        src="../../../assets/images/standup.jpg"
                        class="img-fluid"
                        alt=""
                      />

                      <div class="block-description1">
                        <h6 class="iq-title">
                          <a @click="gotoPage('/detail/' + item.id)"> </a>
                        </h6>

                        <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                            ><i class="fa fa-play mr-1" aria-hidden="true">
                              Play Now
                            </i>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span style="font-family: 'Speda'">
                              ڤیدیۆکە لێبدە
                            </span>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span style="font-family: 'Janna-LT'">
                              شغل الفيديو
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- video -->



                  <!-- audio contents -->
                  <div
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
              v-if="activeTab === 'audios'"
              v-show="activeTab === 'audios'"
            >
              <li
                class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"
                v-for="(item, index) in audioContents"
                :key="index"
              >
                <div class="block-images position-relative">
                  <div class="img-box">
                    <div>



                      <img
                        v-if="item.content_thumbnail!=null"
                        :src="item.content_thumbnail"
                        class="img-fluid"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../../assets/images/thumbnail.jpeg"
                        class="img-fluid"
                        alt=""
                      />

                      <div class="block-description1">
                        <h6 class="iq-title">
                          <a @click="gotoPage('/detail/' + item.id)"> </a>
                        </h6>

                        <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                            ><i class="fa fa-play mr-1" aria-hidden="true">
                              Play Now
                            </i>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span style="font-family: 'Speda';position: relative;top: -10px;">
                              سروودەکە لێبدە
                            </span>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span style="font-family: 'Janna-LT'">
                              شغل
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- audio -->





            <!-- image contents -->
            <div
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
              v-if="activeTab === 'images'"
              v-show="activeTab === 'images'"
            >
              <li
                class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"
                v-for="(item, index) in imageContents"
                :key="index"
                v-show="item.content_url[$i18n.locale] != null"
              >
                <div class="block-images position-relative">
                  <div class="img-box">
                    <div>
                      <div>
                        <img
                          :src="item.content_thumbnail"
                          class="img-fluid"
                          alt="image"
                        style="filter: blur(2px) !important;"
                        />

                        <div class="block-description1">
                          <h6 class="iq-title">
                            <a @click="gotoPage('/detail/' + item.id)">
                            </a>
                          </h6>

                          <div
                            class="hover-buttons"
                            v-if="$i18n.locale == 'en'"
                          >
                            <a
                              @click="gotoPage('/detail/' + item.id)"
                              role="button"
                              class="btn btn-hover"
                              ><i class="fa fa-play mr-1" aria-hidden="true">
                                View
                              </i>
                            </a>
                          </div>
                          <div
                            class="hover-buttons"
                            v-if="$i18n.locale == 'ku'"
                          >
                            <a
                              @click="gotoPage('/detail/' + item.id)"
                              role="button"
                              class="btn btn-hover"
                            >
                              <i class="fa fa-play mr-1" aria-hidden="true">
                              </i>
                              <span style="font-family: 'Speda'"> بینین </span>
                            </a>
                          </div>
                          <div
                            class="hover-buttons"
                            v-if="$i18n.locale == 'ar'"
                          >
                            <a
                              @click="gotoPage('/detail/' + item.id)"
                              role="button"
                              class="btn btn-hover"
                            >
                              <i class="fa fa-play mr-1" aria-hidden="true"></i>
                              <span style="font-family: 'Janna-LT'">عرض </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- image -->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import AlertServie from "../../../services/errors"
import ApiService from "../../../services/api"

export default {
  name: "PopularShow",
  props: ["videoContents", "imageContents","audioContents", "activeTab"],
  components: {},

  data() {
    return {
      showImagePopup: false,
      imageContentPath: "",
      favOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        prevArrow:
          '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    }
  },

  mounted() {},

  methods: {
    hasItems(tab) {
      // Check if the given tab has items
      return this[tab].length > 0
    },

    closeContent() {
      this.imageContentPath = ""
      this.showImagePopup = false
      //  this.imagePopup = false
    },

    openContent(id) {
      this.showImagePopup = true
      var self = this
      ApiService.openContent(id).then((response) => {
        this.$store.commit("setPoints", response.data.user_points)
        self.imageContentPath = response.data.data.content_url.ku
      })
    },

    gotoPage(link) {
      var points = this.$store.state.points
      if (points > 0) {
        this.$router.push(link)
      } else {
        if (this.$i18n.locale === "en") {
          AlertServie.errorMessage(
            "You have used all your daily points. You will receive 3 more points tomorrow."
          )
        } else if (this.$i18n.locale === "ar") {
          AlertServie.errorMessage(
            ".لقد استخدمت كل نقاطك اليومية. ستحصل على 3 نقاط إضافية غدًا"
          )
        } else {
          AlertServie.errorMessage(
            ".هەموو خاڵەکانی ڕۆژانەت بەکارهێناوە. بەیانی 3 خاڵی تر وەردەگریت"
          )

        }
      }
    },
    next() {
      this.$refs.dSlick.next()
    },
    prev() {
      this.$refs.dSlick.prev()
    },
  },
}
</script>

<style scoped lang="scss">
.hover-buttons .btn {
  height: 50px;
  width: 130px;
}

.block-description1 {
  position: absolute;
  left: 25px;
  bottom: 10px;
  z-index: 999;
  display: flex;
  justify-content: end;
  flex-direction: column;
}

.fa {
  font-size: 16px !important;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .fa {
    font-size: 12px !important;
    padding-top: 10px;
  }
  span {
    font-size: 12px !important;
  }
}

.main-title {
  font-size: 22px;
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.tabs {
  display: flex;
}

button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

button.active {
  background: #FED03A;
  color: #f0f0f0;
}

.tab-content {
  margin-top: 20px;
}
</style>
